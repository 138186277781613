/* eslint-disable @typescript-eslint/naming-convention */
import commonEN from '../public/locales/en/common.json';
import inicioEN from '../public/locales/en/pages/inicio.json';
import nosotrosEN from '../public/locales/en/pages/nosotros.json';
import productosEN from '../public/locales/en/pages/productos.json';
import productoEN from '../public/locales/en/pages/producto.json';
import serviciosEN from '../public/locales/en/pages/servicios.json';
import proyectosEN from '../public/locales/en/pages/proyectos.json';
import proyectoEN from '../public/locales/en/pages/proyecto.json';
import contactoEN from '../public/locales/en/pages/contacto.json';
import notFoundEN from '../public/locales/en/pages/404.json';
import errorEN from '../public/locales/en/pages/error.json';
import templateEN from '../public/locales/en/pages/template.json';
import footerEN from '../public/locales/en/footer.json';
import cotizaTuProyectoEN from '../public/locales/en/modals/cotiza-tu-proyecto.json';
import cotizaTuServicioEN from '../public/locales/en/modals/cotiza-tu-servicio.json';
import graciasEN from '../public/locales/en/pages/gracias.json';
import blogEN from '../public/locales/en/pages/blog.json';
import blogsEN from '../public/locales/en/pages/blogs.json';

import commonES from '../public/locales/es/common.json';
import inicioES from '../public/locales/es/pages/inicio.json';
import nosotrosES from '../public/locales/es/pages/nosotros.json';
import productosES from '../public/locales/es/pages/productos.json';
import productoES from '../public/locales/es/pages/producto.json';
import serviciosES from '../public/locales/es/pages/servicios.json';
import proyectosES from '../public/locales/es/pages/proyectos.json';
import proyectoES from '../public/locales/es/pages/proyecto.json';
import contactoES from '../public/locales/es/pages/contacto.json';
import notFoundES from '../public/locales/es/pages/404.json';
import errorES from '../public/locales/es/pages/error.json';
import templateES from '../public/locales/es/pages/template.json';
import footerES from '../public/locales/es/footer.json';
import cotizaTuProyectoES from '../public/locales/es/modals/cotiza-tu-proyecto.json';
import cotizaTuServicioES from '../public/locales/es/modals/cotiza-tu-servicio.json';
import graciasES from '../public/locales/es/pages/gracias.json';
import blogES from '../public/locales/es/pages/blog.json';
import blogsES from '../public/locales/es/pages/blogs.json';

export default {
  // This is the list of languages your application supports
  supportedLngs: ['ES', 'EN'],
  // This is the language you want to use in case
  // if the user language is not in the supportedLngs
  fallbackLng: 'ES',
  // The default namespace of i18next is "translation", but you can customize it here
  defaultNS: 'common',
  // Disabling suspense is recommended
  react: {useSuspense: false},

  resources: {
    ES: {
      common: commonES,
      footer: footerES,
      modals: {
        'cotiza-tu-servicio': cotizaTuServicioES,
        'cotiza-tu-proyecto': cotizaTuProyectoES,
      },
      pages: {
        inicio: inicioES,
        nosotros: nosotrosES,
        productos: productosES,
        producto: productoES,
        servicios: serviciosES,
        proyectos: proyectosES,
        proyecto: proyectoES,
        contacto: contactoES,
        '404': notFoundES,
        error: errorES,
        template: templateES,
        gracias: graciasES,
        blog: blogES,
        blogs: blogsES,
      },
    },
    EN: {
      common: commonEN,
      footer: footerEN,
      modals: {
        'cotiza-tu-servicio': cotizaTuServicioEN,
        'cotiza-tu-proyecto': cotizaTuProyectoEN,
      },
      pages: {
        inicio: inicioEN,
        nosotros: nosotrosEN,
        productos: productosEN,
        producto: productoEN,
        servicios: serviciosEN,
        proyectos: proyectosEN,
        proyecto: proyectoEN,
        contacto: contactoEN,
        '404': notFoundEN,
        error: errorEN,
        template: templateEN,
        gracias: graciasEN,
        blog: blogEN,
        blogs: blogsEN,
      },
    },
  },
};
